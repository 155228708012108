// import Vue from 'vue'
// import axios from 'axios'
import moment from 'moment'
import {cloneDeep} from 'lodash'

import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'

const defaultSettings = () => ({
  selectedImageIds: [],
  algorithm: 'Old', // 'Old', 'AbsoluteTreatment', 'RelativeTreatment', 'OldExtended'
  minimumCorrelation: 0.36,
  classLimit: 0.5,
  outerClassLimit: 0.85,
  zoneCount: 5,
  shownImageId: null,
  orderId: null,
  buffer: 10,
  useRedBorder: true
})

const defineInitialDateRanges = () => {
  const currentYear = moment().year()
  const years = Array.from({length: currentYear - 2015 + 1}).map((x, i) => currentYear - i)

  return years.map(year => ({
    from: `${year}-05-01`,
    to: `${year}-10-20`
  }))
}

const mergeDateRanges = (dateRanges1, dateRanges2) => {
  const newDateRanges = dateRanges2.filter(a => !dateRanges1.some(b => a.from === b.from && a.to === b.to))

  return [...dateRanges1, ...newDateRanges]
}

const savedSettings = {} // {[fieldId]: {satelliteImages, loadedDateRanges, ignoredImageCount, settings}}

export default {
  namespaced: true,
  state: {
    borderGeometry: null,
    cultivations: [],
    satelliteImages: [],
    ignoredImageCount: null,
    scheduledDateRanges: [],
    loadingDateRanges: [],
    loadedDateRanges: [],
    settings: defaultSettings(),
    ui: {
      shownChannel: 'ndvi',
      filter: null
    },
    mapCountForOrder: null
  },
  getters: {
    yearsWithCultivations (state) {
      return state.cultivations.reduce((years, cultivation) => {
        for (const year of cultivation.harvestYears) {
          if (!years.includes(year)) {
            years.push(year)
          }
        }
        return years
      }, [])
    },
    nextDateRanges (state, getters, rootState, rootGetters) {
      if (!state.loadedDateRanges.length) {
        return state.scheduledDateRanges
      }

      const fromDates = state.loadedDateRanges.map(x => x.from).sort((a, b) => a.localeCompare(b))
      const toDates = state.loadedDateRanges.map(x => x.to).sort((a, b) => a.localeCompare(b))

      const minDate = moment(fromDates[0])
      const maxDate = moment(toDates[toDates.length - 1])

      // TODO make less brute force
      const gaps = []
      let date = minDate.clone()
      let start = null
      while (date.isSameOrBefore(maxDate)) {
        const encompassingRange = state.loadedDateRanges.find(x => date.isSame(x.from, 'day') || date.isSame(x.to, 'day') || date.isBetween(x.from, x.to, 'days'))
        if (encompassingRange) {
          if (start) {
            gaps.push({from: start.format('YYYY-MM-DD'), to: date.clone().subtract(1, 'days').format('YYYY-MM-DD')})
            start = null
            date = moment(encompassingRange.to).clone()
          }
        } else if (!start) {
          start = date.clone()
        }

        date.add(1, 'days')
      }

      const nextYearPast = minDate.year() - 1
      const nextYearFuture = maxDate.year() + 1

      return mergeDateRanges([
        {from: `${nextYearPast}-05-01`, to: `${nextYearPast}-10-20`},
        ...gaps,
        {from: `${nextYearFuture}-05-01`, to: `${nextYearFuture}-10-20`}
      ], state.scheduledDateRanges)
    }
  },
  mutations: {
    ...makeSetters([
      'borderGeometry',
      'cultivations',
      'ui.shownChannel',
      'ui.filter',
      'settings.minimumCorrelation',
      'settings.shownImageId',
      'settings.zoneCount',
      'settings.classLimit',
      'settings.outerClassLimit',
      'settings.algorithm',
      'settings.orderId',
      'settings.buffer',
      'settings.useRedBorder',
      'mapCountForOrder'
    ]),
    toggleImage (state, imageId) {
      if (state.settings.selectedImageIds.includes(imageId)) {
        state.settings.selectedImageIds = state.settings.selectedImageIds.filter(x => x !== imageId)
      } else {
        state.settings.selectedImageIds.push(imageId)
      }
    },
    clear (state) {
      state.borderGeometry = null
      state.cultivations = []
      state.satelliteImages = []
      state.ignoredImageCount = null
      state.scheduledDateRanges = []
      state.loadedDateRanges = []
      state.settings = defaultSettings()
    },
    addSatelliteImages (state, {dateRanges, images, ignoredImageCount}) {
      // for image deduplication
      const seenIds = state.satelliteImages.reduce((lookup, image) => {
        lookup[image.id] = true
        return lookup
      }, {})

      const deduplicatedImages = images ? images.reduce((array, image) => {
        // for testing: fix dto format
        image.recordingDate = image.recordingDate.substring(0, 10)
        // for testing: create surrogate id for UI
        image.id = `${image.recordingDate}-${image.dataSource}`

        if (seenIds[image.id]) return array

        seenIds[image.id] = true
        array.push(image)
        return array
      }, []) : []

      state.satelliteImages.push(...deduplicatedImages)
      // keep rest of state consistent
      state.scheduledDateRanges = state.scheduledDateRanges.filter(x => !dateRanges.some(y => x.to === y.to && x.from === y.from))
      state.loadingDateRanges = state.loadingDateRanges.filter(x => !dateRanges.some(y => x.to === y.to && x.from === y.from))

      state.settings.selectedImageIds = state.settings.selectedImageIds.filter(id => state.satelliteImages.find(x => x.id === id))
      if (!state.satelliteImages.find(x => x.id === state.settings.shownImageId)) {
        state.settings.shownImageId = null
      }

      state.loadedDateRanges.push(...dateRanges)
      state.ignoredImageCount += ignoredImageCount
    },
    saveState (state, fieldId) {
      const {satelliteImages, scheduledDateRanges, loadedDateRanges, ignoredImageCount, settings} = state
      savedSettings[fieldId] = cloneDeep({
        satelliteImages,
        scheduledDateRanges,
        loadedDateRanges,
        ignoredImageCount,
        settings
      })
    },
    restoreState (state, fieldId) {
      if (savedSettings[fieldId]) {
        Object.assign(state, savedSettings[fieldId])
      }
    },
    scheduleDateRanges (state, dateRanges) {
      state.scheduledDateRanges = mergeDateRanges(state.scheduledDateRanges, dateRanges)
    },
    startLoadingDateRanges (state, dateRanges) {
      state.loadingDateRanges = mergeDateRanges(state.loadingDateRanges, dateRanges)
    }
  },
  actions: {
    loadCultivationsForField ({commit, rootState, rootGetters}) {
      const url = '/api/v2/frs/{harvestYear}/fields/{fieldId}/cultivations/all'

      return smarterGet(url, {
        id: 'frs.zoneMaps.getAllCultivationDataForField',
        inputs: {
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear,
          fieldId: () => rootGetters['fieldRecordSystem/fieldId']
        },
        expiry: 60,
        sharedCache: true,
        onResult (cultivations) {
          commit('setCultivations', cultivations.filter(cult => cult.type === 'main'))
        }
      })
    },
    loadNextScheduledSatelliteImages ({state, commit, dispatch, rootGetters, rootState}) {
      const url = '/api/v2/field-info/fields/{fieldId}/available-imagery/{harvestYear}'

      const dateRange = state.scheduledDateRanges[0]

      if (!dateRange /* || !rootState.fieldRecordSystem || !rootState.fieldRecordSystem.zoneMaps */) return

      commit('startLoadingDateRanges', [dateRange])

      return smarterPost(url, [dateRange], {
        id: 'frs.zoneMaps.getSatelliteImagery',
        inputs: {
          dateRange: () => `${dateRange.from}>${dateRange.to}`,
          fieldId: () => rootGetters['fieldRecordSystem/fieldId'],
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        expiry: 60,
        sharedCache: true,
        onResult (response) {
          commit('addSatelliteImages', {dateRanges: [dateRange], ...response})
          dispatch('loadNextScheduledSatelliteImages')
        }
      })
    },
    loadSatelliteImages ({state, commit, dispatch}, dateRanges) {
      commit('scheduleDateRanges', dateRanges)

      if (!state.loadingDateRanges.length) {
        dispatch('loadNextScheduledSatelliteImages')
      }
    },
    loadBorderGeometry ({commit, rootState, rootGetters}) {
      return smarterGet('/api/v2/entities/fields/{fieldId}/geodata/{harvestYear}', {
        id: 'zoneMaps.borderGeometry',
        expiry: 0, // NOTE does not work properly with high expiration because store location is unloaded on module exit
        inputs: {
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear,
          fieldId: () => rootGetters['fieldRecordSystem/fieldId']
        },
        onResult (geometry) {
          commit('setBorderGeometry', geometry)
        }
      })
    },
    async loadPrerequisiteData ({commit, dispatch, rootGetters}) {
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      commit('clear')

      await Promise.all([
        dispatch('loadCultivationsForField'),
        dispatch('loadBorderGeometry')
      ])

      if (savedSettings[fieldId]) {
        // NOTE when modifying the field geometry without fully reloading the page, the cache will be broken, currently there is no cache invalidation for this case
        commit('restoreState', fieldId)

        // NOTE no await on purpose, should not block
        dispatch('loadNextScheduledSatelliteImages')
      } else {
        const dateRanges = defineInitialDateRanges()
        // NOTE no await on purpose, should not block
        dispatch('loadSatelliteImages', dateRanges)
      }
    },
    async createZoneMap ({state, dispatch, rootGetters, rootState}, quit) {
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const {harvestYear} = rootState.fieldRecordSystem.userSettings

      // NOTE to make this less awkward, useRedBorder should probably be moved out of settings
      const {useRedBorder, buffer, selectedImageIds, ...dto} = state.settings
      dto.buffer = useRedBorder ? buffer : null
      dto.selectedImages = selectedImageIds.map(id => {
        const {recordingDate, dataSource} = state.satelliteImages.find(x => x.id === id)
        return {recordingDate, dataSource}
      })

      await smarterPost(`/api/v2/field-info/fields/${fieldId}/zone-maps/${harvestYear}`, dto, {
        id: 'frs.zoneMaps.createZoneMap'
      })
    },
    getMapCountForOrder ({state, commit}) {
      commit('setMapCountForOrder', null)

      const url = '/api/v2/field-info/orders/{orderId}/map-count'

      return smarterGet(url, {
        id: 'frs.zoneMaps.getMapCountForOrder',
        inputs: {
          orderId: () => state.settings.orderId
        },
        expiry: 0,
        sharedCache: true,
        onResult (count) {
          commit('setMapCountForOrder', count)
        }
      })
    },
    resetMapCount ({commit}) {
      commit('setMapCountForOrder', null)
    },
    save ({commit, rootGetters}) {
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      commit('saveState', fieldId)
    }
  }
}
